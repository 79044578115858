import React from 'react';
import { navigate } from 'gatsby';
import ProductDetails from '../components/ProductDetails/ProductDetails';


const ProductPage = () => {

  if (typeof window !== 'undefined') {
    navigate('/')
  }

  return <div />
  // return <ProductDetails/>
};

export default ProductPage;
